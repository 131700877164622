<template>
    <div
        v-if="props.errors"
        class="text-xs mt-1 text-red-600 dark:text-red-400"
    >
        <ul v-if="isArray(props.errors)">
            <li
                v-for="error in props.errors"
                :key="error"
            >
                {{ error }}
            </li>
        </ul>

        <div v-else>
            {{ props.errors }}
        </div>
    </div>
</template>

<script setup lang="ts">
    import isArray from 'lodash/isArray';

    export interface Props {
        errors?: string[] | string | null;
    }

    const props = withDefaults(defineProps<Props>(), {
        errors: null,
    });
</script>
